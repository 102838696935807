import { Footer } from "@getwellen/valesco";
import React, { memo } from "react";
import { Outlet } from "react-router-dom";

const Main: React.FC<{ children?: React.ReactNode; showFooter?: boolean }> = ({
  children,
  showFooter = true
}) => (
  <>
    {children || <Outlet />}
    {showFooter && (
      <Footer
        links={[
          {
            href: "#",
            label: "Terms of Service"
          },
          {
            href: "#",
            label: "Privacy Policy"
          }
        ]}
        socialLinks={[]}
      />
    )}
  </>
);

export default memo(Main);
