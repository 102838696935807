import React, { createContext, useContext, useEffect, useState } from "react";

type Geolocation = {
  ip?: string;
  country?: string;
  country_code?: string;
  region?: string;
  city?: string;
  postal?: string;
  latitude?: number;
  longitude?: number;
  timezone?: string;
};

const geolocation = {};

const GeolocationContext = createContext<Geolocation>(geolocation);

const useGeolocation = () => {
  const context = useContext(GeolocationContext);

  if (!context) {
    throw new Error("useGeolocation must be used within a GeolocationProvider");
  }

  return context;
};

const GeolocationProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [geolocation, setGeolocation] = useState<Geolocation>(() =>
    JSON.parse(sessionStorage.getItem("geolocation") || "{}")
  );

  useEffect(() => {
    if (Object.keys(geolocation as object).length > 0) return;

    fetch(
      "https://ipapi.co/json/?" +
        `access_key=${import.meta.env.VITE_GEOLOCATION_API_KEY}`
    ).then(async (response) => {
      const data = await response.json();
      window.sessionStorage.setItem("geolocation", JSON.stringify(data));
      setGeolocation(data);
    });
  }, []);

  return (
    <GeolocationContext.Provider value={geolocation as Geolocation}>
      {children}
    </GeolocationContext.Provider>
  );
};

export { GeolocationProvider, useGeolocation };
