import React, { createContext, useContext } from "react";
import { useBoolean } from "react-use";

type Context = {
  isOverlayOpen: boolean;
  setIsOverlayOpen: (value: boolean) => void;
};

const OverlayContext = createContext<Context>({
  isOverlayOpen: false,
  setIsOverlayOpen: () => null
});

export const OverlayProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const [isOverlayOpen, setIsOverlayOpen] = useBoolean(false);

  return (
    <OverlayContext.Provider value={{ isOverlayOpen, setIsOverlayOpen }}>
      {children}
    </OverlayContext.Provider>
  );
};

export const useOverlay = () => useContext(OverlayContext);
