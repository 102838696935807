import { Button, Overlay, OverlaySize } from "@getwellen/valesco";
import { useCurrentUser } from "contexts/CurrentUserContext";
import { useAcceptProviderDisclaimerMutation } from "graphql/rails-api";
import { memo } from "react";
import { useNavigate } from "react-router-dom";

const AcceptTermsOverlay = () => {
  const navigate = useNavigate();
  const { refetch, currentUserProvider } = useCurrentUser();
  const [acceptTerms] = useAcceptProviderDisclaimerMutation();
  const onContinue = () => {
    acceptTerms({
      context: { clientName: "rails-api" },
      variables: {
        providerId: String(currentUserProvider?.id)
      }
    }).then(async () => {
      await refetch();
      navigate("/dashboard", { replace: true });
    });
  };

  return (
    <Overlay className="flex flex-col" size={OverlaySize.Small}>
      <h1 className="mb-4 font-display text-4xl">Welcome to Wellen</h1>
      <p>
        By using our services, you agree to the{" "}
        <a className="underline" href="/terms" rel="noreferrer" target="_blank">
          Terms of Service
        </a>{" "}
        and{" "}
        <a
          className="underline"
          href="/privacy"
          rel="noreferrer"
          target="_blank"
        >
          Privacy Policy
        </a>
        .
      </p>
      <div className="flex justify-end">
        <Button onClick={onContinue}>Continue</Button>
      </div>
    </Overlay>
  );
};

export default memo(AcceptTermsOverlay);
