import { Auth0Context, Auth0Provider } from "@auth0/auth0-react";
import { ToastNotification } from "@getwellen/valesco";
import algoliasearch from "algoliasearch/lite";
import { AnalyticsProvider } from "contexts/AnalyticsContext";
import {
  CurrentUserContext,
  CurrentUserProvider
} from "contexts/CurrentUserContext";
import { useEnv } from "contexts/EnvContext";
import {
  FeatureFlagContext,
  FeatureFlagProvider
} from "contexts/FeatureFlagContext";
import { GeolocationProvider } from "contexts/GeolocationContext";
import { GraphqlProvider } from "contexts/GraphqlContext";
import { OverlayProvider } from "contexts/OverlayContext";
import VideoApiProvider from "contexts/VideoApiContext";
import LoadingPage from "pages/LoadingPage";
import { useMemo } from "react";
import { InstantSearch } from "react-instantsearch";
import { SkeletonTheme } from "react-loading-skeleton";
import { BrowserRouter } from "react-router-dom";
import { Router } from "routes/Router";

export const indexName =
  import.meta.env.VITE_ALGOLIA_INDEX_NAME || "Exercise_development";

const searchClient = algoliasearch(
  import.meta.env.VITE_ALGOLIA_APP_ID || "",
  import.meta.env.VITE_ALGOLIA_SEARCH_KEY || ""
);

function App() {
  const { AUTH0_DOMAIN, AUTH0_CLIENT_ID, AUTH0_AUDIENCE } = useEnv();

  const loader = useMemo(() => <LoadingPage />, []);

  return (
    <AnalyticsProvider>
      <GeolocationProvider>
        <SkeletonTheme baseColor="#BEBDB9" highlightColor="#EEECE7">
          <Auth0Provider
            authorizationParams={{
              audience: AUTH0_AUDIENCE,
              redirect_uri: window.location.origin + "/authCallback"
            }}
            clientId={AUTH0_CLIENT_ID}
            domain={AUTH0_DOMAIN}
          >
            <Auth0Context.Consumer>
              {({ isLoading }) =>
                isLoading ? (
                  loader
                ) : (
                  <InstantSearch
                    future={{
                      preserveSharedStateOnUnmount: true
                    }}
                    indexName={indexName}
                    initialUiState={{
                      [indexName]: {
                        sortBy: `${indexName}_name_asc`
                      }
                    }}
                    searchClient={searchClient}
                  >
                    <GraphqlProvider>
                      <VideoApiProvider>
                        <FeatureFlagProvider loadingComponent={loader}>
                          <FeatureFlagContext.Consumer>
                            {({ isLoading, loadingComponent }) =>
                              isLoading ? (
                                loadingComponent
                              ) : (
                                <CurrentUserProvider loadingComponent={loader}>
                                  <CurrentUserContext.Consumer>
                                    {({ isLoading, loadingComponent }) =>
                                      isLoading ? (
                                        loadingComponent
                                      ) : (
                                        <OverlayProvider>
                                          <BrowserRouter>
                                            <Router />
                                          </BrowserRouter>
                                        </OverlayProvider>
                                      )
                                    }
                                  </CurrentUserContext.Consumer>
                                </CurrentUserProvider>
                              )
                            }
                          </FeatureFlagContext.Consumer>
                        </FeatureFlagProvider>
                      </VideoApiProvider>
                    </GraphqlProvider>
                  </InstantSearch>
                )
              }
            </Auth0Context.Consumer>
          </Auth0Provider>
          <ToastNotification />
        </SkeletonTheme>
      </GeolocationProvider>
    </AnalyticsProvider>
  );
}

export default App;
