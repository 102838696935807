import React, { createContext, useContext } from "react";

const CMS_API_URL = import.meta.env.VITE_CMS_API_URL || "http://localhost:1337";
const CMS_API_AUTH_TOKEN = import.meta.env.VITE_CMS_API_AUTH_TOKEN || "";
const RAILS_API_URL = import.meta.env.VITE_RAILS_API_URL || "";
const RAILS_API_TOKEN = import.meta.env.VITE_RAILS_API_TOKEN || "";
const AUTH0_DOMAIN = import.meta.env.VITE_AUTH0_DOMAIN || "";
const AUTH0_CLIENT_ID = import.meta.env.VITE_AUTH0_CLIENT_ID || "";
const AUTH0_AUDIENCE = import.meta.env.VITE_AUTH0_AUDIENCE;
const SEGMENT_KEY = import.meta.env.VITE_SEGMENT_KEY || "";

interface EnvInterface {
  SEGMENT_KEY: string;
  CMS_API_URL: string;
  CMS_API_AUTH_TOKEN: string;
  RAILS_API_URL: string;
  RAILS_API_TOKEN: string;
  AUTH0_DOMAIN: string;
  AUTH0_CLIENT_ID: string;
  AUTH0_AUDIENCE: string;
}

const dotenv: EnvInterface = {
  SEGMENT_KEY,
  CMS_API_URL,
  CMS_API_AUTH_TOKEN,
  RAILS_API_URL,
  RAILS_API_TOKEN,
  AUTH0_DOMAIN,
  AUTH0_CLIENT_ID,
  AUTH0_AUDIENCE
};

const EnvContext = createContext<EnvInterface>(dotenv);

const useEnv = () => {
  const context = useContext(EnvContext);

  if (!context) {
    throw new Error(`useEnv must be used within a EnvProvider`);
  }

  return context;
};

const EnvProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return <EnvContext.Provider value={dotenv}>{children}</EnvContext.Provider>;
};

export { EnvProvider, useEnv };
