import { useAuth0 } from "@auth0/auth0-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { httpBatchLink, loggerLink } from "@trpc/client";
import { useState } from "react";
import superjson from "superjson";
import { trpc } from "utils/video-api";
export default function VideoApiProvider({
  children
}: {
  children: React.ReactNode;
}) {
  const { getAccessTokenSilently } = useAuth0();

  const trpcConfig = {
    transformer: superjson,
    links: [
      loggerLink({
        enabled: (opts) =>
          import.meta.env.NODE_ENV === "development" ||
          (opts.direction === "down" && opts.result instanceof Error)
      }),
      httpBatchLink({
        url:
          (import.meta.env.VITE_VIDEO_API_URL || "http://localhost:3010") +
          "/trpc",
        fetch(url, options) {
          return fetch(url, {
            ...options,
            credentials: "include"
          });
        },
        headers: async () => {
          return {
            authorization: `Bearer ${await getAccessTokenSilently()}`
          };
        }
      })
    ]
  };

  const [queryClient] = useState(() => new QueryClient());
  const [trpcClient] = useState(() => trpc.createClient(trpcConfig));

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </trpc.Provider>
  );
}
