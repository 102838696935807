import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@getwellen/valesco";
import React, { memo } from "react";
import { useNavigate } from "react-router-dom";

const ErrorPage: React.FC<{
  errorMessage: string;
  errorCode: number;
}> = ({ errorMessage, errorCode = 500 }) => {
  const navigate = useNavigate();
  const { isAuthenticated, logout } = useAuth0();

  return (
    <div className="bg-lynch-700 text-white flex h-screen flex-col items-center justify-center">
      <p className="text-3xl">Error {errorCode}</p>
      <p className="mb-8 text-base">{errorMessage}</p>
      <Button
        action="contrast"
        className="mb-1"
        onClick={() => {
          if (isAuthenticated) {
            logout({
              logoutParams: {
                returnTo: window.location.origin
              }
            });
          } else {
            navigate(-1);
          }
        }}
        variant="bold"
      >
        Go Back
      </Button>
    </div>
  );
};

export default memo(ErrorPage);
