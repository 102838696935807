import { ApolloQueryResult } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Exact,
  GetCurrentUserQuery,
  Provider,
  useGetCurrentUserQuery,
  User
} from "graphql/rails-api";
import React, { createContext, useContext, useMemo } from "react";

interface CurrentUserContext {
  currentUser: User | undefined;
  currentUserProvider: Provider | undefined;
  isLoading: boolean;
  error?: string;
  loadingComponent?: React.ReactNode;
  refetch: (
    variables?:
      | Partial<
          Exact<{
            [key: string]: never;
          }>
        >
      | undefined
  ) => Promise<ApolloQueryResult<GetCurrentUserQuery>> | null;
}

const defaultState: CurrentUserContext = {
  currentUser: undefined,
  currentUserProvider: undefined,
  isLoading: false,
  error: undefined,
  loadingComponent: null,
  refetch: () => null
};

const CurrentUserContext = createContext<CurrentUserContext>(defaultState);

const CurrentUserProvider: React.FC<{
  children?: React.ReactNode;
  loadingComponent?: React.ReactNode;
}> = ({ children, loadingComponent }) => {
  const { isLoading: authIsLoading, isAuthenticated, error } = useAuth0();

  const {
    data: currentUserData,
    loading: isLoading,
    refetch
  } = useGetCurrentUserQuery({
    context: { clientName: "rails-api" },
    skip: authIsLoading || !isAuthenticated,
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first"
  });

  const currentUser = useMemo(
    () => (currentUserData?.getCurrentUser as User) || null,
    [currentUserData]
  );
  const provider = useMemo(() => currentUser?.providers?.[0], [currentUser]);

  return (
    <CurrentUserContext.Provider
      value={{
        currentUser,
        currentUserProvider: provider,
        isLoading,
        refetch,
        error: error?.toString(),
        loadingComponent
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
};

const useCurrentUser = () => {
  const context = useContext(CurrentUserContext);

  if (!context)
    throw new Error("useCurrentUser must be used within CurrentUserProvider");

  return context;
};

export { CurrentUserContext, CurrentUserProvider, useCurrentUser };
