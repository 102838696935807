import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  ISO8601DateTime: any;
  JSON: any;
};

export type Activity = {
  __typename?: 'Activity';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  slug: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type Assessment = {
  __typename?: 'Assessment';
  assessmentType: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  slug: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Attributes for auth providers */
export type AuthProviderAttributes = {
  provider: Scalars['String'];
  uid: Scalars['String'];
};

export type Cart = {
  __typename?: 'Cart';
  currency: Scalars['String'];
  discountAmount: Scalars['Float'];
  discountAmountDisplay: Scalars['String'];
  discountCode?: Maybe<Scalars['String']>;
  items: Array<CartItem>;
  paymentTerms?: Maybe<Scalars['String']>;
  subtotal: Scalars['Float'];
  subtotalDisplay: Scalars['String'];
  total: Scalars['Float'];
  totalDisplay: Scalars['String'];
};

export type CartItem = {
  __typename?: 'CartItem';
  amount: Scalars['Float'];
  amountDisplay: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  itemType: CartItemType;
};

export enum CartItemType {
  Discount = 'DISCOUNT',
  FreeTrial = 'FREE_TRIAL',
  Price = 'PRICE'
}

/** Autogenerated return type of ChangePasswordAdmin. */
export type ChangePasswordAdminPayload = {
  __typename?: 'ChangePasswordAdminPayload';
  message: Scalars['String'];
};

/** Autogenerated return type of ChangePassword. */
export type ChangePasswordPayload = {
  __typename?: 'ChangePasswordPayload';
  message: Scalars['String'];
};

export type CustomerioUser = {
  __typename?: 'CustomerioUser';
  /** Customerio id for the user */
  cioId: Scalars['String'];
  /** Email address of the user */
  email: Scalars['String'];
};

export enum Day {
  Friday = 'Friday',
  Monday = 'Monday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
  Thursday = 'Thursday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday'
}

export type Exercise = {
  __typename?: 'Exercise';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  slug: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type FeatureFlag = {
  __typename?: 'FeatureFlag';
  /** Whether or not the feature is enabled for this user */
  enabled: Scalars['Boolean'];
  /** The feature name */
  feature: Scalars['String'];
};

/** Autogenerated return type of GeolocateAddress. */
export type GeolocateAddressPayload = {
  __typename?: 'GeolocateAddressPayload';
  /** Latitude for address */
  lat?: Maybe<Scalars['Float']>;
  /** Longitude for address */
  lng?: Maybe<Scalars['Float']>;
};

/** Autogenerated return type of ImportExercise. */
export type ImportExercisePayload = {
  __typename?: 'ImportExercisePayload';
  message: Scalars['String'];
};

/** Autogenerated return type of ImportExercises. */
export type ImportExercisesPayload = {
  __typename?: 'ImportExercisesPayload';
  message: Scalars['String'];
};

export type ImportStatus = {
  __typename?: 'ImportStatus';
  status: Scalars['String'];
};

/** Autogenerated return type of ImportWorkout. */
export type ImportWorkoutPayload = {
  __typename?: 'ImportWorkoutPayload';
  message: Scalars['String'];
};

/** Autogenerated return type of ImportWorkouts. */
export type ImportWorkoutsPayload = {
  __typename?: 'ImportWorkoutsPayload';
  message: Scalars['String'];
};

export enum Interval {
  Annually = 'ANNUALLY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY'
}

export type Lead = {
  __typename?: 'Lead';
  createdAt: Scalars['ISO8601DateTime'];
  email: Scalars['String'];
  id: Scalars['ID'];
  source: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  utms?: Maybe<Scalars['JSON']>;
};

/** Location input type */
export type LocationAttributes = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  postal?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
};

export type MusicPreference = {
  __typename?: 'MusicPreference';
  mix?: Maybe<Scalars['String']>;
  station?: Maybe<Scalars['String']>;
  volume?: Maybe<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptProviderDisclaimer: Provider;
  /** Accept a disclaimer */
  acceptUserDisclaimer: User;
  /** Cancel a subscription */
  cancelSubscription: Subscription;
  changePassword: ChangePasswordPayload;
  changePasswordAdmin: ChangePasswordAdminPayload;
  /** Complete a user onboarding */
  completeUserOnboarding: User;
  completeWorkoutProgramDayTask: WorkoutProgramDayTask;
  createLead: Lead;
  createPatientWorkout: PatientWorkout;
  createPersonalizationSwap: UserPersonalizationSwap;
  /** Create a provider user */
  createProviderUser: User;
  createShareableWorkout: Shareable;
  /** Create a subscription for the current user using the subscription attributes input type */
  createSubscription: Subscription;
  /** Create a user using the user attributes input type */
  createUser: User;
  createUserAssessmentResult: UserAssessmentResult;
  /** Create a user profile using the user profile attributes input type */
  createUserProfile: UserProfile;
  /** Create well tip result */
  createUserWellTipResult: UserWellTipResult;
  /** Create a user with a profile */
  createUserWithProfile: User;
  createWorkoutProgram: WorkoutProgram;
  createWorkoutRating: WorkoutRating;
  /** Geolocate a zip code */
  geolocateAddress?: Maybe<GeolocateAddressPayload>;
  importExercise: ImportExercisePayload;
  importExercises: ImportExercisesPayload;
  importWorkout: ImportWorkoutPayload;
  importWorkouts: ImportWorkoutsPayload;
  /** Login a provider user */
  loginProviderUser: User;
  /** Base mutation type that defines all mutations */
  loginUser: User;
  personalizeWorkoutProgram: WorkoutProgram;
  personalizeWorkoutProgramAdmin: WorkoutProgram;
  removePersonalizationSwap: UserPersonalizationSwap;
  /** Request a password reset, which will trigger an email to the customer if they have an account */
  requestPasswordReset: RequestPasswordResetPayload;
  resumeSubscription: Subscription;
  /** Show welcome message to user */
  seenWelcomeMessage: User;
  startAssessment: Assessment;
  startOnboarding?: Maybe<StartOnboardingPayload>;
  /** Start a subscription */
  startSubscription: Subscription;
  /** Start a subscription trial */
  startSubscriptionTrial: Subscription;
  startWorkout: Workout;
  startWorkoutProgramDay: WorkoutProgramDay;
  startWorkoutProgramDayTask?: Maybe<WorkoutProgramDayTask>;
  /** Submit a waitlist interest for a lead */
  submitWaitlistInterestForLead: WaitlistInterest;
  subscribeToNotification: UserNotification;
  subscribeToNotificationAdmin: UserNotification;
  /** Unlock a workout program day */
  unlockWorkoutProgramDayAdmin: WorkoutProgramDay;
  unsubscribeFromNotification: UserNotification;
  unsubscribeFromNotificationAdmin: UserNotification;
  /** Update a subscriptions payment method with a setup intent */
  updateSubscriptionPaymentMethod: Subscription;
  updateSubscriptionPlan: Subscription;
  /** Update a user given user attributes */
  updateUser: User;
  /** Update a user given user attributes */
  updateUserAdmin: User;
  /** Update an existing user music preference */
  updateUserMusicPreference: User;
  /** Update a user phone number */
  updateUserPhone: User;
  /** Update a user phone number */
  updateUserPhoneAdmin: User;
  /** Update a user profile given user profile attributes */
  updateUserProfile: UserProfile;
  /** Update a user profile given user profile attributes */
  updateUserProfileAdmin: UserProfile;
  /** Validate a password reset token */
  validatePasswordResetToken: ValidatePasswordResetTokenPayload;
  validatePromoCode: ValidatePromoCodePayload;
};


export type MutationAcceptProviderDisclaimerArgs = {
  providerId: Scalars['ID'];
};


export type MutationCancelSubscriptionArgs = {
  id: Scalars['ID'];
};


export type MutationChangePasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationChangePasswordAdminArgs = {
  password: Scalars['String'];
  userId: Scalars['ID'];
};


export type MutationCompleteWorkoutProgramDayTaskArgs = {
  id: Scalars['ID'];
  manual?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateLeadArgs = {
  anonymousId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  event?: InputMaybe<Scalars['String']>;
  source: Scalars['String'];
};


export type MutationCreatePatientWorkoutArgs = {
  attributes: PatientWorkoutAttributes;
  providerId: Scalars['ID'];
};


export type MutationCreatePersonalizationSwapArgs = {
  attributes: UserPersonalizationSwapAttributes;
};


export type MutationCreateProviderUserArgs = {
  attributes: UserAttributes;
  locationAttributes?: InputMaybe<LocationAttributes>;
};


export type MutationCreateShareableWorkoutArgs = {
  origin?: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
};


export type MutationCreateSubscriptionArgs = {
  attributes: SubscriptionAttributes;
  locationAttributes?: InputMaybe<LocationAttributes>;
};


export type MutationCreateUserArgs = {
  attributes: UserAttributes;
};


export type MutationCreateUserAssessmentResultArgs = {
  attributes: UserAssessmentResultAttributes;
};


export type MutationCreateUserProfileArgs = {
  attributes: UserProfileAttributes;
};


export type MutationCreateUserWellTipResultArgs = {
  attributes: UserWellTipResultAttributes;
};


export type MutationCreateUserWithProfileArgs = {
  locationAttributes?: InputMaybe<LocationAttributes>;
  profileAttributes: UserProfileAttributes;
  subscriptionAttributes?: InputMaybe<SubscriptionAttributes>;
  userAttributes: UserAttributes;
};


export type MutationCreateWorkoutRatingArgs = {
  attributes: WorkoutRatingAttributes;
};


export type MutationGeolocateAddressArgs = {
  address: Scalars['String'];
};


export type MutationImportExerciseArgs = {
  slug: Scalars['String'];
};


export type MutationImportWorkoutArgs = {
  slug: Scalars['String'];
};


export type MutationLoginProviderUserArgs = {
  locationAttributes?: InputMaybe<LocationAttributes>;
};


export type MutationLoginUserArgs = {
  location?: InputMaybe<LocationAttributes>;
};


export type MutationPersonalizeWorkoutProgramAdminArgs = {
  userId: Scalars['ID'];
};


export type MutationRemovePersonalizationSwapArgs = {
  id: Scalars['ID'];
};


export type MutationRequestPasswordResetArgs = {
  email: Scalars['String'];
  returnUrl: Scalars['String'];
};


export type MutationResumeSubscriptionArgs = {
  subscriptionId: Scalars['ID'];
};


export type MutationStartAssessmentArgs = {
  slug: Scalars['String'];
};


export type MutationStartOnboardingArgs = {
  anonymousId: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  healthHistory?: InputMaybe<Array<ProfileItemAttributes>>;
};


export type MutationStartSubscriptionArgs = {
  attributes: SubscriptionAttributes;
};


export type MutationStartSubscriptionTrialArgs = {
  attributes: SubscriptionAttributes;
  unlockProgram?: InputMaybe<Scalars['Boolean']>;
};


export type MutationStartWorkoutArgs = {
  slug: Scalars['String'];
};


export type MutationStartWorkoutProgramDayArgs = {
  id: Scalars['ID'];
};


export type MutationStartWorkoutProgramDayTaskArgs = {
  id: Scalars['ID'];
};


export type MutationSubmitWaitlistInterestForLeadArgs = {
  leadId: Scalars['ID'];
  metadata?: InputMaybe<Scalars['JSON']>;
  waitlistType: Scalars['String'];
};


export type MutationSubscribeToNotificationArgs = {
  metadata: UserNotificationMetadataAttributes;
  notificationMethod: UserNotificationMethod;
  notificationType: NotificationType;
};


export type MutationSubscribeToNotificationAdminArgs = {
  metadata: UserNotificationMetadataAttributes;
  notificationMethod: UserNotificationMethod;
  notificationType: NotificationType;
  userId: Scalars['ID'];
};


export type MutationUnlockWorkoutProgramDayAdminArgs = {
  id: Scalars['ID'];
};


export type MutationUnsubscribeFromNotificationArgs = {
  notificationType: NotificationType;
};


export type MutationUnsubscribeFromNotificationAdminArgs = {
  notificationType: NotificationType;
  userId: Scalars['ID'];
};


export type MutationUpdateSubscriptionPaymentMethodArgs = {
  stripeSetupIntentId: Scalars['String'];
  subscriptionId: Scalars['ID'];
};


export type MutationUpdateSubscriptionPlanArgs = {
  stripePriceId: Scalars['String'];
  subscriptionId: Scalars['ID'];
};


export type MutationUpdateUserArgs = {
  attributes: UserUpdateAttributes;
};


export type MutationUpdateUserAdminArgs = {
  attributes: UserUpdateAttributes;
  userId: Scalars['ID'];
};


export type MutationUpdateUserMusicPreferenceArgs = {
  mix?: InputMaybe<Scalars['String']>;
  station?: InputMaybe<Scalars['String']>;
  volume?: InputMaybe<Scalars['Float']>;
};


export type MutationUpdateUserPhoneArgs = {
  phone: Scalars['String'];
};


export type MutationUpdateUserPhoneAdminArgs = {
  phone: Scalars['String'];
  userId: Scalars['ID'];
};


export type MutationUpdateUserProfileArgs = {
  attributes: UserProfileAttributes;
};


export type MutationUpdateUserProfileAdminArgs = {
  attributes: UserProfileAttributes;
  userId: Scalars['ID'];
};


export type MutationValidatePasswordResetTokenArgs = {
  token: Scalars['String'];
};


export type MutationValidatePromoCodeArgs = {
  code: Scalars['String'];
  countryCode?: InputMaybe<Scalars['String']>;
  stripePriceId?: InputMaybe<Scalars['String']>;
};

export enum NotificationType {
  WorkoutReminder = 'WORKOUT_REMINDER'
}

export type Patient = {
  __typename?: 'Patient';
  /** When the provider was created */
  createdAt: Scalars['ISO8601DateTime'];
  /** First name of the patient */
  email: Scalars['String'];
  /** First name of the patient */
  firstName: Scalars['String'];
  /** The ID of the patient */
  id: Scalars['ID'];
  /** Last name of the patient */
  lastName: Scalars['String'];
  patientWorkouts: Array<PatientWorkout>;
  provider: Provider;
  /** Status of the patient */
  status: PatientStatus;
  /** Last time the provider was updated */
  updatedAt: Scalars['ISO8601DateTime'];
  user?: Maybe<User>;
};

/** Attributes for creating a patient */
export type PatientAttributes = {
  /** The email of the patient */
  email: Scalars['String'];
  /** The first name of the patient */
  firstName: Scalars['String'];
  /** The last name of the patient */
  lastName: Scalars['String'];
};

export type PatientResults = {
  __typename?: 'PatientResults';
  hasMore: Scalars['Boolean'];
  results: Array<Patient>;
  totalCount: Scalars['Int'];
};

export type PatientSearchFilters = {
  provider?: InputMaybe<Scalars['String']>;
};

export enum PatientStatus {
  Onboarded = 'ONBOARDED',
  Onboarding = 'ONBOARDING',
  Prospecting = 'PROSPECTING',
  Subscribed = 'SUBSCRIBED',
  Trialing = 'TRIALING',
  Unsubscribed = 'UNSUBSCRIBED'
}

export type PatientWorkout = {
  __typename?: 'PatientWorkout';
  /** When the provider was created */
  createdAt: Scalars['ISO8601DateTime'];
  /** The ID of the patient */
  id: Scalars['ID'];
  patient: Patient;
  /** When the workout was sent to the patient */
  sentOn?: Maybe<Scalars['ISO8601DateTime']>;
  /** The slug of the workout */
  slug?: Maybe<Scalars['String']>;
  /** The status of the workout */
  status: Scalars['String'];
  /** Last time the provider was updated */
  updatedAt: Scalars['ISO8601DateTime'];
  /** This patients workout */
  workout?: Maybe<Workout>;
  /** This patients workout template */
  workoutTemplate: WorkoutTemplate;
};

/** Attributes for creating a workout template */
export type PatientWorkoutAttributes = {
  /** The patient to create the workout for */
  patient: PatientAttributes;
  /** The workout template to create the workout from */
  workoutTemplate: WorkoutTemplateAttributes;
};

export enum PlanType {
  Premium = 'PREMIUM',
  Starter = 'STARTER'
}

export type Profile = {
  __typename?: 'Profile';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  items?: Maybe<Array<ProfileItem>>;
  profileType?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type ProfileItem = {
  __typename?: 'ProfileItem';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  key: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  value: Scalars['String'];
};

/** Attributes for creating profile items */
export type ProfileItemAttributes = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type Provider = {
  __typename?: 'Provider';
  /** When the provider was created */
  createdAt: Scalars['ISO8601DateTime'];
  /** Whether the provider has accepted the terms of service */
  hasAcceptedTerms: Scalars['Boolean'];
  /** The ID of the provider */
  id: Scalars['ID'];
  /** Name of the provider */
  name: Scalars['String'];
  /** Number of patients associated with the provider */
  patientCount?: Maybe<Scalars['Int']>;
  /** Number of programs sent by the provider */
  programsSent?: Maybe<Scalars['Int']>;
  /** URL-friendly slug of the provider */
  slug: Scalars['String'];
  /** Last time the provider was updated */
  updatedAt: Scalars['ISO8601DateTime'];
};

export type ProviderResults = {
  __typename?: 'ProviderResults';
  hasMore: Scalars['Boolean'];
  results: Array<Provider>;
  totalCount: Scalars['Int'];
};

/** Base query type that defines all queries */
export type Query = {
  __typename?: 'Query';
  getCurrentProviderUser?: Maybe<Provider>;
  /** Get the currently authenticated user */
  getCurrentUser?: Maybe<User>;
  getCurrentUserAssessmentResultById: UserAssessmentResult;
  getCurrentUserAssessmentResultBySlugAndTaskId: UserAssessmentResult;
  /** Get the current user assessments history, ordered by created at */
  getCurrentUserAssessmentResultHistory?: Maybe<Array<UserAssessmentResult>>;
  /** Get the current user assessments overview by assessment type */
  getCurrentUserAssessmentResultOverview?: Maybe<Array<UserAssessmentResult>>;
  getCurrentUserCart: Cart;
  getCurrentUserCompletedWorkoutPrograms: Array<WorkoutProgram>;
  /** Get the current users notifications */
  getCurrentUserNotifications: Array<UserNotification>;
  /** Get the current users payment history */
  getCurrentUserPaymentHistory?: Maybe<StripeResults>;
  /** Get the current users swaps */
  getCurrentUserPersonalizationSwaps?: Maybe<Array<UserPersonalizationSwap>>;
  /** Get the currently authenticated users' profile */
  getCurrentUserProfile: UserProfile;
  /** Get a current user's recommended plan based on health history */
  getCurrentUserRecommendedPlans?: Maybe<RecommendedPlan>;
  getCurrentUserStripeSetupIntent: StripeSetupIntent;
  /** Get the currently authneticated users' subscription */
  getCurrentUserSubscription?: Maybe<Subscription>;
  /** Get the currently authenticated users' subscription billing period end */
  getCurrentUserSubscriptionBillingPeriodEnd?: Maybe<Scalars['ISO8601DateTime']>;
  getCurrentUserSubscriptionSummary?: Maybe<SubscriptionSummary>;
  /** Get the current user's workout program */
  getCurrentUserWorkoutProgram?: Maybe<WorkoutProgram>;
  /** Get the current user's workout program day by key */
  getCurrentUserWorkoutProgramDayByKey: WorkoutProgramDay;
  /** Get a days task by its key for the current users currently active workout program */
  getCurrentUserWorkoutProgramDayTaskByKey: WorkoutProgramDayTask;
  getCustomerioUser?: Maybe<CustomerioUser>;
  /** Get an exercise by its url-friendly slug */
  getExerciseBySlug?: Maybe<Exercise>;
  getExerciseImportStatus?: Maybe<ImportStatus>;
  getExercisesImportStatus?: Maybe<ImportStatus>;
  /** Get all feature flags and whether or not they are enabled */
  getFeatureFlags: Array<FeatureFlag>;
  getLeadByUuid?: Maybe<Lead>;
  getPatientById: Patient;
  getPatientWorkoutById?: Maybe<PatientWorkout>;
  getPatientWorkoutBySlug?: Maybe<PatientWorkout>;
  getPatientWorkoutsByProviderId?: Maybe<Array<PatientWorkout>>;
  /** Get a list of patients for the current provider */
  getPatients?: Maybe<PatientResults>;
  getProviders?: Maybe<ProviderResults>;
  /** Get recommended subscription plan based on health history */
  getRecommendedSubscriptionPlan: RecommendedPlan;
  /** Given profile items, get a recommended workout persona  */
  getRecommendedWorkoutPersonaFromFitnessProfile?: Maybe<RecommendedWorkoutProgram>;
  getRecommendedWorkoutPersonaFromHealthHistory?: Maybe<RecommendedWorkoutProgram>;
  /** Get a shareable by code */
  getShareableByCode?: Maybe<Shareable>;
  getStripePriceByIds?: Maybe<Array<StripePrice>>;
  getSubscriptionPlanChangeSummary?: Maybe<SubscriptionPlanChangeSummary>;
  getUserNotificationsByUserId?: Maybe<Array<UserNotification>>;
  getUserSubscriptionByUserId?: Maybe<Subscription>;
  getWorkoutById?: Maybe<Workout>;
  /** Get a workout by its url-friendly slug */
  getWorkoutBySlug?: Maybe<Workout>;
  /** Get the status of a single workout import */
  getWorkoutImportStatus: ImportStatus;
  getWorkoutProgramById: WorkoutProgram;
  getWorkoutsBySlugs?: Maybe<Array<Workout>>;
  /** Get the status of the workouts import (for all workouts) */
  getWorkoutsImportStatus?: Maybe<ImportStatus>;
  searchProviders: SearchResult;
};


/** Base query type that defines all queries */
export type QueryGetCurrentUserAssessmentResultByIdArgs = {
  id: Scalars['ID'];
};


/** Base query type that defines all queries */
export type QueryGetCurrentUserAssessmentResultBySlugAndTaskIdArgs = {
  slug: Scalars['String'];
  taskId?: InputMaybe<Scalars['String']>;
};


/** Base query type that defines all queries */
export type QueryGetCurrentUserCartArgs = {
  currencyCode?: InputMaybe<Scalars['String']>;
  promoCode?: InputMaybe<Scalars['String']>;
  stripePriceId: Scalars['String'];
};


/** Base query type that defines all queries */
export type QueryGetCurrentUserPaymentHistoryArgs = {
  page?: InputMaybe<Scalars['String']>;
};


/** Base query type that defines all queries */
export type QueryGetCurrentUserPersonalizationSwapsArgs = {
  swapContextSlug?: InputMaybe<Scalars['String']>;
  swapContextType?: InputMaybe<Scalars['String']>;
};


/** Base query type that defines all queries */
export type QueryGetCurrentUserWorkoutProgramDayByKeyArgs = {
  key: Scalars['String'];
};


/** Base query type that defines all queries */
export type QueryGetCurrentUserWorkoutProgramDayTaskByKeyArgs = {
  key: Scalars['String'];
};


/** Base query type that defines all queries */
export type QueryGetCustomerioUserArgs = {
  cioId: Scalars['String'];
};


/** Base query type that defines all queries */
export type QueryGetExerciseBySlugArgs = {
  slug: Scalars['String'];
};


/** Base query type that defines all queries */
export type QueryGetExerciseImportStatusArgs = {
  slug: Scalars['String'];
};


/** Base query type that defines all queries */
export type QueryGetLeadByUuidArgs = {
  uuid: Scalars['ID'];
};


/** Base query type that defines all queries */
export type QueryGetPatientByIdArgs = {
  id: Scalars['ID'];
};


/** Base query type that defines all queries */
export type QueryGetPatientWorkoutByIdArgs = {
  id: Scalars['ID'];
};


/** Base query type that defines all queries */
export type QueryGetPatientWorkoutBySlugArgs = {
  slug: Scalars['String'];
};


/** Base query type that defines all queries */
export type QueryGetPatientWorkoutsByProviderIdArgs = {
  providerId: Scalars['ID'];
};


/** Base query type that defines all queries */
export type QueryGetPatientsArgs = {
  filter?: InputMaybe<PatientSearchFilters>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};


/** Base query type that defines all queries */
export type QueryGetProvidersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};


/** Base query type that defines all queries */
export type QueryGetRecommendedSubscriptionPlanArgs = {
  healthHistory: Array<ProfileItemAttributes>;
};


/** Base query type that defines all queries */
export type QueryGetRecommendedWorkoutPersonaFromFitnessProfileArgs = {
  fitness: Array<ProfileItemAttributes>;
};


/** Base query type that defines all queries */
export type QueryGetRecommendedWorkoutPersonaFromHealthHistoryArgs = {
  healthHistory: Array<ProfileItemAttributes>;
};


/** Base query type that defines all queries */
export type QueryGetShareableByCodeArgs = {
  code: Scalars['String'];
};


/** Base query type that defines all queries */
export type QueryGetStripePriceByIdsArgs = {
  countryCode?: InputMaybe<Scalars['String']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  stripePriceIds: Array<Scalars['String']>;
};


/** Base query type that defines all queries */
export type QueryGetSubscriptionPlanChangeSummaryArgs = {
  stripePriceId: Scalars['String'];
  subscriptionId: Scalars['ID'];
};


/** Base query type that defines all queries */
export type QueryGetUserNotificationsByUserIdArgs = {
  userId: Scalars['ID'];
};


/** Base query type that defines all queries */
export type QueryGetUserSubscriptionByUserIdArgs = {
  userId: Scalars['ID'];
};


/** Base query type that defines all queries */
export type QueryGetWorkoutByIdArgs = {
  id: Scalars['ID'];
};


/** Base query type that defines all queries */
export type QueryGetWorkoutBySlugArgs = {
  slug: Scalars['String'];
};


/** Base query type that defines all queries */
export type QueryGetWorkoutImportStatusArgs = {
  slug: Scalars['String'];
};


/** Base query type that defines all queries */
export type QueryGetWorkoutProgramByIdArgs = {
  id: Scalars['ID'];
};


/** Base query type that defines all queries */
export type QueryGetWorkoutsBySlugsArgs = {
  slugs: Array<Scalars['String']>;
};


/** Base query type that defines all queries */
export type QuerySearchProvidersArgs = {
  hasVideoVisits?: InputMaybe<Scalars['Boolean']>;
  inNetwork?: InputMaybe<Scalars['Boolean']>;
  insurance?: InputMaybe<Scalars['String']>;
  location: Scalars['String'];
  providerName?: InputMaybe<Scalars['String']>;
};

/** A type for a user's recommended plan */
export type RecommendedPlan = {
  __typename?: 'RecommendedPlan';
  planType?: Maybe<PlanType>;
};

export type RecommendedWorkoutProgram = {
  __typename?: 'RecommendedWorkoutProgram';
  workoutPersona: WorkoutPersona;
  workoutType: WorkoutType;
};

/** Autogenerated return type of RequestPasswordReset. */
export type RequestPasswordResetPayload = {
  __typename?: 'RequestPasswordResetPayload';
  message: Scalars['String'];
};

export type SearchResult = {
  __typename?: 'SearchResult';
  count?: Maybe<Scalars['Int']>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  input?: Maybe<Scalars['JSON']>;
  records?: Maybe<Scalars['JSON']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type Shareable = {
  __typename?: 'Shareable';
  code?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  deletedAt?: Maybe<Scalars['ISO8601DateTime']>;
  expiresAt?: Maybe<Scalars['ISO8601DateTime']>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  isExpired: Scalars['Boolean'];
  shareable: ShareableType;
  shortUrl?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Shareable types can be many things */
export type ShareableType = Workout;

/** Autogenerated return type of StartOnboarding. */
export type StartOnboardingPayload = {
  __typename?: 'StartOnboardingPayload';
  message: Scalars['String'];
};

/** A Stripe setup intent object */
export type StripeCharge = {
  __typename?: 'StripeCharge';
  amount: Scalars['Int'];
  amountDisplay: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  currency: Scalars['String'];
  id: Scalars['ID'];
  receiptPdf?: Maybe<Scalars['String']>;
  receiptUrl: Scalars['String'];
  status: Scalars['String'];
};

export type StripePrice = {
  __typename?: 'StripePrice';
  currency: Scalars['String'];
  currencySymbol: Scalars['String'];
  id: Scalars['ID'];
  interval: Interval;
  intervalLength: Scalars['Int'];
  msrp: Scalars['Int'];
  msrpDisplay: Scalars['String'];
  unitPrice: Scalars['Int'];
  unitPriceDisplay: Scalars['String'];
  unitPricePerMonth: Scalars['Int'];
  unitPricePerMonthDisplay: Scalars['String'];
};

export type StripeResults = {
  __typename?: 'StripeResults';
  data: Array<StripeCharge>;
  hasMore: Scalars['Boolean'];
  nextPage?: Maybe<Scalars['String']>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Stripe setup intent object */
export type StripeSetupIntent = {
  __typename?: 'StripeSetupIntent';
  clientSecret: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  activeAt?: Maybe<Scalars['ISO8601DateTime']>;
  cancelAt?: Maybe<Scalars['ISO8601DateTime']>;
  canceledAt?: Maybe<Scalars['ISO8601DateTime']>;
  createdAt: Scalars['ISO8601DateTime'];
  currencyCode: Scalars['String'];
  hasPayment: Scalars['Boolean'];
  id: Scalars['ID'];
  interval?: Maybe<Interval>;
  metadata?: Maybe<Scalars['JSON']>;
  planType?: Maybe<PlanType>;
  state?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  trialDaysRemaining?: Maybe<Scalars['Int']>;
  trialEndsAt?: Maybe<Scalars['ISO8601DateTime']>;
  trialStartedAt?: Maybe<Scalars['ISO8601DateTime']>;
  updatedAt: Scalars['ISO8601DateTime'];
  userId: Scalars['ID'];
};

/** Attributes for creating a subscription */
export type SubscriptionAttributes = {
  interval?: InputMaybe<Interval>;
  planType?: InputMaybe<PlanType>;
  promoCode?: InputMaybe<Scalars['String']>;
  stripePriceId?: InputMaybe<Scalars['String']>;
  stripeSetupIntentId?: InputMaybe<Scalars['String']>;
  subscriptionId?: InputMaybe<Scalars['ID']>;
};

export type SubscriptionPlanChangeSummary = {
  __typename?: 'SubscriptionPlanChangeSummary';
  cardType: Scalars['String'];
  currencySymbol: Scalars['String'];
  dueNow: Scalars['Float'];
  dueNowDisplay: Scalars['String'];
  id: Scalars['ID'];
  isTrial: Scalars['Boolean'];
  last4: Scalars['String'];
  newPrice: Scalars['Float'];
  newPriceDisplay: Scalars['String'];
  prorationDate: Scalars['ISO8601DateTime'];
  renewalDate: Scalars['ISO8601DateTime'];
};

export type SubscriptionSummary = {
  __typename?: 'SubscriptionSummary';
  billingPeriodEndAt?: Maybe<Scalars['ISO8601DateTime']>;
  cardType?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  displayPrice?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
};

export type SwapContext = Workout;

export type Swappable = Exercise;

/** Taskable can be a workout */
export type Taskable = Activity | Assessment | WellTip | Workout;

export enum Time {
  Am = 'AM',
  Pm = 'PM'
}

export enum Timezone {
  Cst = 'CST',
  Est = 'EST',
  Mst = 'MST',
  Pst = 'PST'
}

export type User = {
  __typename?: 'User';
  /** Number of completed assessments */
  assessmentsCompleted: Scalars['Int'];
  authProviders?: Maybe<Array<UserAuthProvider>>;
  /** When the user was created */
  createdAt: Scalars['ISO8601DateTime'];
  /** The users current workout persona */
  currentWorkoutPersona?: Maybe<UserWorkoutPersona>;
  currentWorkoutProgram?: Maybe<WorkoutProgram>;
  /** The users current workout type */
  currentWorkoutType?: Maybe<WorkoutType>;
  /** Email address pf the user */
  email?: Maybe<Scalars['String']>;
  /** Sha256 hash of the users email address */
  emailHash: Scalars['String'];
  /** First name of the user */
  firstName?: Maybe<Scalars['String']>;
  /** Whether or not a user has accepted the disclaimer */
  hasAcceptedDisclaimer: Scalars['Boolean'];
  /**
   * Whether or not the user has seen the welcome message
   *                                               via the modal
   */
  hasSeenWelcomeMessage: Scalars['Boolean'];
  hasSubscription: Scalars['Boolean'];
  /** Internal id for the user */
  id: Scalars['ID'];
  intercomHash?: Maybe<Scalars['String']>;
  /** Whether or not a user has a draft subscription */
  isDraft: Scalars['Boolean'];
  /** Whether or not a user has onboarded through intake */
  isOnboarded: Scalars['Boolean'];
  /** Whether or not the user has a payment failure */
  isPaymentFailure: Scalars['Boolean'];
  /** Whether or not the user is subscribed */
  isSubscribed: Scalars['Boolean'];
  /** Whether or not a user has an expired trial and is not subscribed */
  isTrialExpired: Scalars['Boolean'];
  /** Whether or not a user is trialing a subscription */
  isTrialing: Scalars['Boolean'];
  /** Whether or not the user is subscribed */
  isUnsubscribed: Scalars['Boolean'];
  /** Last name of the user */
  lastName?: Maybe<Scalars['String']>;
  /** Users music preference */
  musicPreferences?: Maybe<MusicPreference>;
  /** Phone number for the user */
  phone?: Maybe<Scalars['String']>;
  /** Providers the user is associated with */
  providers?: Maybe<Array<Provider>>;
  /** Last time the user was updated */
  updatedAt: Scalars['ISO8601DateTime'];
  userProfile?: Maybe<UserProfile>;
  /** Number of completed programs */
  workoutProgramsCompleted: Scalars['Int'];
};

export type UserAssessmentResult = {
  __typename?: 'UserAssessmentResult';
  assessment: Assessment;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  metadata?: Maybe<Scalars['JSON']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Attributes for creating a new user assessment result */
export type UserAssessmentResultAttributes = {
  /** Assessment id to log results for */
  assessmentId: Scalars['ID'];
  /** Metadata where results live */
  metadata?: InputMaybe<Scalars['JSON']>;
};

/** Attributes for creating or updating a user */
export type UserAttributes = {
  authProvider: AuthProviderAttributes;
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  hasAcceptedDisclaimer?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
};

export type UserAuthProvider = {
  __typename?: 'UserAuthProvider';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  provider?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  userId: Scalars['ID'];
};

export type UserNotification = {
  __typename?: 'UserNotification';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  isSubscribed?: Maybe<Scalars['Boolean']>;
  isUnsubscribed?: Maybe<Scalars['Boolean']>;
  metadata?: Maybe<UserNotificationMetadata>;
  notificationMethod: UserNotificationMethod;
  notificationType?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['ISO8601DateTime']>;
  unsubscribedAt?: Maybe<Scalars['ISO8601DateTime']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type UserNotificationMetadata = {
  __typename?: 'UserNotificationMetadata';
  notificationDays?: Maybe<Array<Day>>;
  notificationHour?: Maybe<Scalars['Int']>;
  notificationMinute?: Maybe<Scalars['Int']>;
  notificationTime?: Maybe<Time>;
  notificationTimezone?: Maybe<Timezone>;
};

export type UserNotificationMetadataAttributes = {
  notificationDays: Array<Day>;
  notificationHour: Scalars['Int'];
  notificationMinute: Scalars['Int'];
  notificationTime: Time;
  notificationTimezone: Timezone;
};

export enum UserNotificationMethod {
  Email = 'EMAIL',
  Text = 'TEXT',
  TextAndEmail = 'TEXT_AND_EMAIL'
}

export type UserPersonalizationSwap = {
  __typename?: 'UserPersonalizationSwap';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  replace: Swappable;
  swapContext?: Maybe<SwapContext>;
  target: Swappable;
  updatedAt: Scalars['ISO8601DateTime'];
  userId: Scalars['ID'];
};

/** Attributes for creating a personalization swap */
export type UserPersonalizationSwapAttributes = {
  replaceSlug: Scalars['String'];
  replaceType: Scalars['String'];
  swapContextSlug: Scalars['String'];
  swapContextType: Scalars['String'];
  targetSlug: Scalars['String'];
  targetType: Scalars['String'];
};

export type UserProfile = {
  __typename?: 'UserProfile';
  active?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['ISO8601DateTime'];
  fitness?: Maybe<Profile>;
  goals?: Maybe<Profile>;
  healthHistory?: Maybe<Profile>;
  id: Scalars['ID'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Attributes for creating or updating a user profile */
export type UserProfileAttributes = {
  fitness?: InputMaybe<Array<ProfileItemAttributes>>;
  goals?: InputMaybe<Array<ProfileItemAttributes>>;
  healthHistory?: InputMaybe<Array<ProfileItemAttributes>>;
};

/** Attributes for creating or updating a user */
export type UserUpdateAttributes = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
};

export type UserWellTipResult = {
  __typename?: 'UserWellTipResult';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  metadata?: Maybe<Scalars['JSON']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Attributes for creating a new user well tip result */
export type UserWellTipResultAttributes = {
  /** Metadata where results live */
  metadata?: InputMaybe<Scalars['JSON']>;
  /** Well tip id to log results for */
  wellTipId: Scalars['ID'];
};

/** A user workout persona */
export type UserWorkoutPersona = {
  __typename?: 'UserWorkoutPersona';
  createdAt: Scalars['ISO8601DateTime'];
  deletedAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  updatedAt: Scalars['ISO8601DateTime'];
  userId?: Maybe<Scalars['ID']>;
  workoutPersona: WorkoutPersona;
  workoutPersonaId?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of ValidatePasswordResetToken. */
export type ValidatePasswordResetTokenPayload = {
  __typename?: 'ValidatePasswordResetTokenPayload';
  message: Scalars['String'];
};

/** Autogenerated return type of ValidatePromoCode. */
export type ValidatePromoCodePayload = {
  __typename?: 'ValidatePromoCodePayload';
  isValid: Scalars['Boolean'];
};

export type WaitlistInterest = {
  __typename?: 'WaitlistInterest';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  metadata?: Maybe<Scalars['JSON']>;
  updatedAt: Scalars['ISO8601DateTime'];
  waitlistType?: Maybe<Scalars['String']>;
};

export type WellTip = {
  __typename?: 'WellTip';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  slug: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type Workout = {
  __typename?: 'Workout';
  createdAt: Scalars['ISO8601DateTime'];
  exercises?: Maybe<Array<Exercise>>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  provider?: Maybe<Provider>;
  slug: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  workoutOrder?: Maybe<Scalars['Int']>;
};

export type WorkoutPersona = {
  __typename?: 'WorkoutPersona';
  createdAt: Scalars['ISO8601DateTime'];
  default?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  slug?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type WorkoutProgram = {
  __typename?: 'WorkoutProgram';
  completedAt?: Maybe<Scalars['ISO8601DateTime']>;
  createdAt: Scalars['ISO8601DateTime'];
  days: Array<WorkoutProgramDay>;
  id: Scalars['ID'];
  isCompleted: Scalars['Boolean'];
  slug: Scalars['String'];
  stats: WorkoutProgramStats;
  updatedAt: Scalars['ISO8601DateTime'];
  userId: Scalars['ID'];
  weeks: Scalars['Int'];
};

export type WorkoutProgramDay = {
  __typename?: 'WorkoutProgramDay';
  completedAt?: Maybe<Scalars['ISO8601DateTime']>;
  createdAt: Scalars['ISO8601DateTime'];
  dayNumber: Scalars['Int'];
  id: Scalars['ID'];
  isCompleted: Scalars['Boolean'];
  isLocked: Scalars['Boolean'];
  isStarted: Scalars['Boolean'];
  key?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  startedAt?: Maybe<Scalars['ISO8601DateTime']>;
  tasks: Array<WorkoutProgramDayTask>;
  updatedAt: Scalars['ISO8601DateTime'];
  weekNumber: Scalars['Int'];
};

export type WorkoutProgramDayTask = {
  __typename?: 'WorkoutProgramDayTask';
  createdAt: Scalars['ISO8601DateTime'];
  /** true */
  day: WorkoutProgramDay;
  id: Scalars['ID'];
  isCompleted: Scalars['Boolean'];
  isStarted: Scalars['Boolean'];
  key?: Maybe<Scalars['String']>;
  taskable: Taskable;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type WorkoutProgramStats = {
  __typename?: 'WorkoutProgramStats';
  weeksCompleted: Scalars['Int'];
  weeksRemaining: Scalars['Int'];
  workoutsCompleted: Scalars['Int'];
  workoutsRemaining: Scalars['Int'];
};

export type WorkoutRating = {
  __typename?: 'WorkoutRating';
  createdAt: Scalars['ISO8601DateTime'];
  difficultyRating?: Maybe<Scalars['Int']>;
  generalFeedback?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  instructorRating?: Maybe<Scalars['Int']>;
  musicRating?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['ISO8601DateTime'];
  voiceRating?: Maybe<Scalars['Int']>;
};

/** Attributes for creating a user workout rating */
export type WorkoutRatingAttributes = {
  difficultyRating: Scalars['Int'];
  generalFeedback?: InputMaybe<Scalars['String']>;
  instructorRating?: InputMaybe<Scalars['Int']>;
  metdata?: InputMaybe<Scalars['JSON']>;
  musicRating?: InputMaybe<Scalars['Int']>;
  voiceRating?: InputMaybe<Scalars['Int']>;
  workoutId: Scalars['ID'];
};

export type WorkoutTemplate = {
  __typename?: 'WorkoutTemplate';
  /** When the template was created */
  createdAt: Scalars['ISO8601DateTime'];
  exercises: Array<WorkoutTemplateExercise>;
  /** The ID of this template */
  id: Scalars['ID'];
  /** The name of this template */
  name?: Maybe<Scalars['String']>;
  /** The provider that owns this template */
  provider: Provider;
  /** The URL-safe slug of this template */
  slug: Scalars['String'];
  /** When the template was last updated */
  updatedAt: Scalars['ISO8601DateTime'];
  workoutOrder?: Maybe<Scalars['Int']>;
  workoutPersona: WorkoutPersona;
};

/** Attributes for creating a workout template */
export type WorkoutTemplateAttributes = {
  /** The exercises in this template */
  exercises: Array<WorkoutTemplateExerciseAttributes>;
  /** The name of this template */
  name: Scalars['String'];
};

export type WorkoutTemplateExercise = {
  __typename?: 'WorkoutTemplateExercise';
  createdAt: Scalars['ISO8601DateTime'];
  deletedAt?: Maybe<Scalars['ISO8601DateTime']>;
  holdDuration?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  instructions?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['Int']>;
  reps?: Maybe<Scalars['Int']>;
  restDuration?: Maybe<Scalars['Int']>;
  sets?: Maybe<Scalars['Int']>;
  slug: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Attributes for creating a workout template exercise */
export type WorkoutTemplateExerciseAttributes = {
  /** The duration to hold the exercise */
  holdDuration?: InputMaybe<Scalars['Int']>;
  /** The instructions of the exercise */
  instructions?: InputMaybe<Scalars['String']>;
  /** The number of reps to perform */
  reps: Scalars['Int'];
  /** The duration to rest between sets */
  restDuration?: InputMaybe<Scalars['Int']>;
  /** The number of sets to perform */
  sets: Scalars['Int'];
  /** The slug of the exercise */
  slug: Scalars['String'];
};

export enum WorkoutType {
  All = 'ALL',
  Base = 'BASE',
  Resistance = 'RESISTANCE',
  Weight = 'WEIGHT',
  WeightResistanceStepLoop = 'WEIGHT_RESISTANCE_STEP_LOOP',
  WeightStep = 'WEIGHT_STEP',
  WeightStepNoProne = 'WEIGHT_STEP_NO_PRONE'
}

export type UserFragmentFragment = { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null, email?: string | null, createdAt: any, updatedAt: any, providers?: Array<{ __typename: 'Provider', id: string, name: string, slug: string, hasAcceptedTerms: boolean }> | null };

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserQuery = { __typename?: 'Query', getCurrentUser?: { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null, email?: string | null, createdAt: any, updatedAt: any, providers?: Array<{ __typename: 'Provider', id: string, name: string, slug: string, hasAcceptedTerms: boolean }> | null } | null };

export type GetPatientWorkoutsByProviderIdQueryVariables = Exact<{
  providerId: Scalars['ID'];
}>;


export type GetPatientWorkoutsByProviderIdQuery = { __typename?: 'Query', getPatientWorkoutsByProviderId?: Array<{ __typename?: 'PatientWorkout', id: string, slug?: string | null, status: string, sentOn?: any | null, patient: { __typename: 'Patient', id: string, firstName: string, lastName: string, email: string }, workout?: { __typename: 'Workout', id: string, slug: string } | null, workoutTemplate: { __typename: 'WorkoutTemplate', id: string, slug: string, name?: string | null } }> | null };

export type GetFeatureFlagsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFeatureFlagsQuery = { __typename?: 'Query', getFeatureFlags: Array<{ __typename?: 'FeatureFlag', feature: string, enabled: boolean }> };

export type CreateProviderUserMutationVariables = Exact<{
  attributes: UserAttributes;
  locationAttributes?: InputMaybe<LocationAttributes>;
}>;


export type CreateProviderUserMutation = { __typename?: 'Mutation', createProviderUser: { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null, email?: string | null, createdAt: any, updatedAt: any, providers?: Array<{ __typename: 'Provider', id: string, name: string, slug: string, hasAcceptedTerms: boolean }> | null } };

export type CreatePatientWorkoutMutationVariables = Exact<{
  providerId: Scalars['ID'];
  attributes: PatientWorkoutAttributes;
}>;


export type CreatePatientWorkoutMutation = { __typename: 'Mutation', createPatientWorkout: { __typename?: 'PatientWorkout', id: string, slug?: string | null, patient: { __typename: 'Patient', id: string }, workout?: { __typename: 'Workout', id: string, slug: string } | null, workoutTemplate: { __typename: 'WorkoutTemplate', id: string, slug: string } } };

export type LoginProviderUserMutationVariables = Exact<{
  locationAttributes?: InputMaybe<LocationAttributes>;
}>;


export type LoginProviderUserMutation = { __typename?: 'Mutation', loginProviderUser: { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null, email?: string | null, createdAt: any, updatedAt: any, providers?: Array<{ __typename: 'Provider', id: string, name: string, slug: string, hasAcceptedTerms: boolean }> | null } };

export type AcceptProviderDisclaimerMutationVariables = Exact<{
  providerId: Scalars['ID'];
}>;


export type AcceptProviderDisclaimerMutation = { __typename?: 'Mutation', acceptProviderDisclaimer: { __typename?: 'Provider', id: string } };

export const UserFragmentFragmentDoc = gql`
    fragment UserFragment on User {
  id
  firstName
  lastName
  email
  providers {
    id
    name
    slug
    hasAcceptedTerms
    __typename
  }
  createdAt
  updatedAt
  __typename
}
    `;
export const GetCurrentUserDocument = gql`
    query GetCurrentUser {
  getCurrentUser {
    id
    firstName
    lastName
    email
    providers {
      id
      name
      slug
      hasAcceptedTerms
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
    `;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
      }
export function useGetCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
        }
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<GetCurrentUserQuery, GetCurrentUserQueryVariables>;
export const GetPatientWorkoutsByProviderIdDocument = gql`
    query GetPatientWorkoutsByProviderId($providerId: ID!) {
  getPatientWorkoutsByProviderId(providerId: $providerId) {
    id
    slug
    status
    sentOn
    patient {
      id
      firstName
      lastName
      email
      __typename
    }
    workout {
      id
      slug
      __typename
    }
    workoutTemplate {
      id
      slug
      name
      __typename
    }
  }
}
    `;

/**
 * __useGetPatientWorkoutsByProviderIdQuery__
 *
 * To run a query within a React component, call `useGetPatientWorkoutsByProviderIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientWorkoutsByProviderIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientWorkoutsByProviderIdQuery({
 *   variables: {
 *      providerId: // value for 'providerId'
 *   },
 * });
 */
export function useGetPatientWorkoutsByProviderIdQuery(baseOptions: Apollo.QueryHookOptions<GetPatientWorkoutsByProviderIdQuery, GetPatientWorkoutsByProviderIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPatientWorkoutsByProviderIdQuery, GetPatientWorkoutsByProviderIdQueryVariables>(GetPatientWorkoutsByProviderIdDocument, options);
      }
export function useGetPatientWorkoutsByProviderIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPatientWorkoutsByProviderIdQuery, GetPatientWorkoutsByProviderIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPatientWorkoutsByProviderIdQuery, GetPatientWorkoutsByProviderIdQueryVariables>(GetPatientWorkoutsByProviderIdDocument, options);
        }
export type GetPatientWorkoutsByProviderIdQueryHookResult = ReturnType<typeof useGetPatientWorkoutsByProviderIdQuery>;
export type GetPatientWorkoutsByProviderIdLazyQueryHookResult = ReturnType<typeof useGetPatientWorkoutsByProviderIdLazyQuery>;
export type GetPatientWorkoutsByProviderIdQueryResult = Apollo.QueryResult<GetPatientWorkoutsByProviderIdQuery, GetPatientWorkoutsByProviderIdQueryVariables>;
export const GetFeatureFlagsDocument = gql`
    query GetFeatureFlags {
  getFeatureFlags {
    feature
    enabled
  }
}
    `;

/**
 * __useGetFeatureFlagsQuery__
 *
 * To run a query within a React component, call `useGetFeatureFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeatureFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeatureFlagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFeatureFlagsQuery(baseOptions?: Apollo.QueryHookOptions<GetFeatureFlagsQuery, GetFeatureFlagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFeatureFlagsQuery, GetFeatureFlagsQueryVariables>(GetFeatureFlagsDocument, options);
      }
export function useGetFeatureFlagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeatureFlagsQuery, GetFeatureFlagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFeatureFlagsQuery, GetFeatureFlagsQueryVariables>(GetFeatureFlagsDocument, options);
        }
export type GetFeatureFlagsQueryHookResult = ReturnType<typeof useGetFeatureFlagsQuery>;
export type GetFeatureFlagsLazyQueryHookResult = ReturnType<typeof useGetFeatureFlagsLazyQuery>;
export type GetFeatureFlagsQueryResult = Apollo.QueryResult<GetFeatureFlagsQuery, GetFeatureFlagsQueryVariables>;
export const CreateProviderUserDocument = gql`
    mutation CreateProviderUser($attributes: UserAttributes!, $locationAttributes: LocationAttributes) {
  createProviderUser(
    attributes: $attributes
    locationAttributes: $locationAttributes
  ) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;
export type CreateProviderUserMutationFn = Apollo.MutationFunction<CreateProviderUserMutation, CreateProviderUserMutationVariables>;

/**
 * __useCreateProviderUserMutation__
 *
 * To run a mutation, you first call `useCreateProviderUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProviderUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProviderUserMutation, { data, loading, error }] = useCreateProviderUserMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *      locationAttributes: // value for 'locationAttributes'
 *   },
 * });
 */
export function useCreateProviderUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateProviderUserMutation, CreateProviderUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProviderUserMutation, CreateProviderUserMutationVariables>(CreateProviderUserDocument, options);
      }
export type CreateProviderUserMutationHookResult = ReturnType<typeof useCreateProviderUserMutation>;
export type CreateProviderUserMutationResult = Apollo.MutationResult<CreateProviderUserMutation>;
export type CreateProviderUserMutationOptions = Apollo.BaseMutationOptions<CreateProviderUserMutation, CreateProviderUserMutationVariables>;
export const CreatePatientWorkoutDocument = gql`
    mutation CreatePatientWorkout($providerId: ID!, $attributes: PatientWorkoutAttributes!) {
  createPatientWorkout(providerId: $providerId, attributes: $attributes) {
    id
    slug
    patient {
      id
      __typename
    }
    workout {
      id
      slug
      __typename
    }
    workoutTemplate {
      id
      slug
      __typename
    }
  }
  __typename
}
    `;
export type CreatePatientWorkoutMutationFn = Apollo.MutationFunction<CreatePatientWorkoutMutation, CreatePatientWorkoutMutationVariables>;

/**
 * __useCreatePatientWorkoutMutation__
 *
 * To run a mutation, you first call `useCreatePatientWorkoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePatientWorkoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPatientWorkoutMutation, { data, loading, error }] = useCreatePatientWorkoutMutation({
 *   variables: {
 *      providerId: // value for 'providerId'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useCreatePatientWorkoutMutation(baseOptions?: Apollo.MutationHookOptions<CreatePatientWorkoutMutation, CreatePatientWorkoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePatientWorkoutMutation, CreatePatientWorkoutMutationVariables>(CreatePatientWorkoutDocument, options);
      }
export type CreatePatientWorkoutMutationHookResult = ReturnType<typeof useCreatePatientWorkoutMutation>;
export type CreatePatientWorkoutMutationResult = Apollo.MutationResult<CreatePatientWorkoutMutation>;
export type CreatePatientWorkoutMutationOptions = Apollo.BaseMutationOptions<CreatePatientWorkoutMutation, CreatePatientWorkoutMutationVariables>;
export const LoginProviderUserDocument = gql`
    mutation LoginProviderUser($locationAttributes: LocationAttributes) {
  loginProviderUser(locationAttributes: $locationAttributes) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;
export type LoginProviderUserMutationFn = Apollo.MutationFunction<LoginProviderUserMutation, LoginProviderUserMutationVariables>;

/**
 * __useLoginProviderUserMutation__
 *
 * To run a mutation, you first call `useLoginProviderUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginProviderUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginProviderUserMutation, { data, loading, error }] = useLoginProviderUserMutation({
 *   variables: {
 *      locationAttributes: // value for 'locationAttributes'
 *   },
 * });
 */
export function useLoginProviderUserMutation(baseOptions?: Apollo.MutationHookOptions<LoginProviderUserMutation, LoginProviderUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginProviderUserMutation, LoginProviderUserMutationVariables>(LoginProviderUserDocument, options);
      }
export type LoginProviderUserMutationHookResult = ReturnType<typeof useLoginProviderUserMutation>;
export type LoginProviderUserMutationResult = Apollo.MutationResult<LoginProviderUserMutation>;
export type LoginProviderUserMutationOptions = Apollo.BaseMutationOptions<LoginProviderUserMutation, LoginProviderUserMutationVariables>;
export const AcceptProviderDisclaimerDocument = gql`
    mutation AcceptProviderDisclaimer($providerId: ID!) {
  acceptProviderDisclaimer(providerId: $providerId) {
    id
  }
}
    `;
export type AcceptProviderDisclaimerMutationFn = Apollo.MutationFunction<AcceptProviderDisclaimerMutation, AcceptProviderDisclaimerMutationVariables>;

/**
 * __useAcceptProviderDisclaimerMutation__
 *
 * To run a mutation, you first call `useAcceptProviderDisclaimerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptProviderDisclaimerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptProviderDisclaimerMutation, { data, loading, error }] = useAcceptProviderDisclaimerMutation({
 *   variables: {
 *      providerId: // value for 'providerId'
 *   },
 * });
 */
export function useAcceptProviderDisclaimerMutation(baseOptions?: Apollo.MutationHookOptions<AcceptProviderDisclaimerMutation, AcceptProviderDisclaimerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptProviderDisclaimerMutation, AcceptProviderDisclaimerMutationVariables>(AcceptProviderDisclaimerDocument, options);
      }
export type AcceptProviderDisclaimerMutationHookResult = ReturnType<typeof useAcceptProviderDisclaimerMutation>;
export type AcceptProviderDisclaimerMutationResult = Apollo.MutationResult<AcceptProviderDisclaimerMutation>;
export type AcceptProviderDisclaimerMutationOptions = Apollo.BaseMutationOptions<AcceptProviderDisclaimerMutation, AcceptProviderDisclaimerMutationVariables>;