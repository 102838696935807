import { useAuth0 } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { useAnalytics } from "contexts/AnalyticsContext";
import { CreateWorkoutTemplateProvider } from "contexts/CreateWorkoutTemplateContext";
import ErrorPage from "pages/ErrorPage";
import AcceptTermsOverlay from "pages/overlays/AcceptTermsOverlay";
import React, { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigationType
} from "react-router-dom";

import MainLayout from "../layouts/Main";
import { PrivateRoute } from "./PrivateRoute";

const DashboardPage = React.lazy(
  () => import("../pages/dashboard/DashboardPage")
);

const ProgramLandingPage = React.lazy(
  () => import("../pages/programs/ProgramLandingPage")
);

const ProgramExercisesPage = React.lazy(
  () => import("../pages/programs/ProgramExercisesPage")
);

const NewProgramOverlay = React.lazy(
  () => import("../pages/overlays/NewProgramOverlay")
);

const GenerateAIProgramPage = React.lazy(
  () => import("pages/dashboard/GenerateAIProgramPage")
);

const NewProgramWithAIOverlay = React.lazy(
  () => import("pages/overlays/NewProgramWithAIOverlay")
);

const EditProgramOverlay = React.lazy(
  () => import("../pages/overlays/EditProgramOverlay")
);

const ExerciseOverlay = React.lazy(
  () => import("../pages/overlays/ExerciseOverlay")
);

const ReorderProgramExercisesOverlay = React.lazy(
  () => import("../pages/overlays/ReorderProgramExercisesOverlay")
);

const SendToPatientOverlay = React.lazy(
  () => import("../pages/overlays/SendToPatientOverlay")
);

const AuthCallbackPage = React.lazy(
  () => import("../pages/auth/AuthCallbackPage")
);

const ConfirmHomeOverlay = React.lazy(
  () => import("../pages/overlays/ConfirmHomeOverlay")
);

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

if (Boolean(import.meta.env.VITE_SENTRY_DSN)) {
  const options: Sentry.BrowserOptions = {
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        )
      })
    ],
    tracesSampleRate: 0.1
  };

  if (import.meta.env.VITE_SENTRY_ENV) {
    options.environment = import.meta.env.VITE_SENTRY_ENV;
  }

  if (import.meta.env.VITE_SENTRY_RELEASE) {
    options.release = import.meta.env.VITE_SENTRY_RELEASE;
  }

  Sentry.init(options);
}

const Router: React.FC = () => {
  const location = useLocation();
  const state = location.state as { backgroundLocation?: Location };
  const backgroundLocation = state?.backgroundLocation;
  const { error } = useAuth0();
  const analytics = useAnalytics();

  useEffect(() => {
    if (analytics) analytics.page(location.pathname);
  }, [location]);

  if (error)
    return (
      <ErrorPage
        errorCode={400}
        errorMessage={
          "There was an error loading your account. Please try again later."
        }
      />
    );

  return (
    <CreateWorkoutTemplateProvider>
      {/* normal routes, rendered under overlays */}
      <SentryRoutes location={backgroundLocation || location}>
        {/* public routes */}
        <Route
          element={
            <React.Suspense fallback={<></>}>
              <AuthCallbackPage />
            </React.Suspense>
          }
          path="/authCallback"
        />
        {/* /private routes */}
        <Route element={<PrivateRoute component={MainLayout} />}>
          <Route path="/programs/:programId">
            <Route
              element={
                <React.Suspense fallback={<></>}>
                  <ProgramLandingPage />
                </React.Suspense>
              }
              index
            />
            <Route
              element={
                <React.Suspense fallback={<></>}>
                  <ProgramExercisesPage />
                </React.Suspense>
              }
              path="/programs/:programId/exercises"
            />
          </Route>
          <Route path="/dashboard">
            <Route
              element={
                <React.Suspense fallback={<></>}>
                  <DashboardPage />
                </React.Suspense>
              }
              index
            />
            <Route
              element={
                <React.Suspense fallback={<></>}>
                  <GenerateAIProgramPage />
                </React.Suspense>
              }
              path="/dashboard/generateAIProgram"
            />
          </Route>
          <Route
            element={<Navigate replace={true} to="/dashboard" />}
            path="*"
          />
        </Route>
      </SentryRoutes>
      {/* render overlay routes, on top of normal routes */}
      {backgroundLocation && (
        <SentryRoutes>
          <Route
            element={
              <React.Suspense fallback={<></>}>
                <AcceptTermsOverlay />
              </React.Suspense>
            }
            path="/provider/terms"
          />
          <Route
            element={
              <React.Suspense fallback={<></>}>
                <NewProgramWithAIOverlay />
              </React.Suspense>
            }
            path="/dashboard/createProgram/ai"
          />
          <Route
            element={
              <React.Suspense fallback={<></>}>
                <NewProgramOverlay />
              </React.Suspense>
            }
            path="/dashboard/createProgram"
          />
          <Route
            element={
              <React.Suspense fallback={<></>}>
                <ConfirmHomeOverlay />
              </React.Suspense>
            }
            path="/confirm-home"
          />
          <Route
            element={
              <React.Suspense fallback={<></>}>
                <EditProgramOverlay />
              </React.Suspense>
            }
            path="/programs/:programId/editProgram"
          />
          <Route
            element={
              <React.Suspense fallback={<></>}>
                <ExerciseOverlay />
              </React.Suspense>
            }
            path="/programs/:programId/exercises/:exerciseSlug"
          />
          <Route
            element={
              <React.Suspense fallback={<></>}>
                <ReorderProgramExercisesOverlay />
              </React.Suspense>
            }
            path="/programs/:programId/reorderExercises"
          />
          <Route
            element={
              <React.Suspense fallback={<></>}>
                <SendToPatientOverlay />
              </React.Suspense>
            }
            path="/programs/:programId/sendToPatient"
          />
        </SentryRoutes>
      )}
    </CreateWorkoutTemplateProvider>
  );
};

export { Router };
