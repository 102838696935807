import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  from,
  InMemoryCache
} from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { setContext } from "@apollo/client/link/context";
import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

import { useEnv } from "./EnvContext";

const GraphqlProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { CMS_API_URL, RAILS_API_URL } = useEnv();
  const { getAccessTokenSilently } = useAuth0();

  const cmsLink = new BatchHttpLink({
    uri: (CMS_API_URL || "").replace(/\/$/, "") + "/graphql"
  });

  const railsApiLink = new BatchHttpLink({
    uri: (RAILS_API_URL || "").replace(/\/$/, "") + "/graphql"
  });

  const railsApiAuthMiddleware = setContext(
    async (_request, previousContext) => {
      const token = await getAccessTokenSilently();

      return Object.assign(previousContext, {
        headers: {
          Authorization: token ? `Bearer ${token}` : ""
        }
      });
    }
  );

  const client = new ApolloClient({
    //
    // ApolloClient expects one client and we can use ApolloLink
    // to split requests, based on context provided, so we can
    // determine which API to hit with an operation.
    //
    // by default, a query will route to the cmsLink but if
    // a query has context: { clientName: 'rails-api' } attached
    // to it, then it will route to the railsApiLink
    //
    link: ApolloLink.split(
      (operation) => operation.getContext().clientName === "rails-api",
      // order matters here -- match first, then second if above is true
      from([railsApiAuthMiddleware, railsApiLink]),
      cmsLink
    ),
    cache: new InMemoryCache(),
    connectToDevTools: import.meta.env.NODE_ENV !== "production"
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export { GraphqlProvider };
