import React, { useCallback, useContext, useEffect, useState } from "react";
import { useSessionStorage } from "react-use";
import { v4 as uuidV4 } from "uuid";

export type CreateWorkoutTemplateExercise = {
  slug: string;
  name: string;
  videoThumbnailUrl: string | null;
  sets: number;
  reps: number;
  holdDuration: number;
  restDuration: number;
  instructions: string;
  isHold: boolean;
};

export type CreateWorkoutTemplate = {
  id: string;
  name: string;
  exercises: CreateWorkoutTemplateExercise[];
};

export type UpdateCreateWorkoutTemplate = (
  data: Partial<CreateWorkoutTemplate>
) => void;

const defaultValues: CreateWorkoutTemplate = {
  id: uuidV4(),
  name: "",
  exercises: []
};

const noop: UpdateCreateWorkoutTemplate = () => {
  throw new Error("Not implemented");
};

const CreateWorkoutTemplateContext =
  React.createContext<CreateWorkoutTemplate>(defaultValues);

const UpdateCreateWorkoutTemplateContext =
  React.createContext<UpdateCreateWorkoutTemplate>(noop);

type CreateWorkoutTemplateProviderProps = {
  children: React.ReactNode;
};

export const CreateWorkoutTemplateProvider: React.FC<
  CreateWorkoutTemplateProviderProps
> = ({ children }) => {
  const [cachedValue, setCachedValue] = useSessionStorage(
    "createWorkoutTemplate",
    defaultValues
  );
  const [value, setValue] = useState<CreateWorkoutTemplate>(() => cachedValue);
  const updateValue = useCallback((data: Partial<CreateWorkoutTemplate>) => {
    setValue((prevValue) => ({ ...prevValue, ...data }));
  }, []);

  // keep the cached value in sync with the value
  useEffect(() => {
    setCachedValue(value);
  }, [value]);

  return (
    <UpdateCreateWorkoutTemplateContext.Provider value={updateValue}>
      <CreateWorkoutTemplateContext.Provider value={value}>
        {children}
      </CreateWorkoutTemplateContext.Provider>
    </UpdateCreateWorkoutTemplateContext.Provider>
  );
};

export const useCreateWorkoutTemplate = () => {
  const context = useContext(CreateWorkoutTemplateContext);

  if (!context) {
    throw new Error(
      `useCreateWorkoutTemplate must be used within a CreateWorkoutTemplateProvider`
    );
  }

  return context;
};

export const useUpdateCreateWorkoutTemplate = () => {
  const context = useContext(UpdateCreateWorkoutTemplateContext);

  if (!context) {
    throw new Error(
      `useUpdateCreateWorkoutTemplate must be used within a CreateWorkoutTemplateProvider`
    );
  }

  return context;
};
